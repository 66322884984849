import type { RouteLocationNormalizedGeneric } from 'vue-router';
import { SponsorType, type RegisterStep } from '~/composables/useRegisterSteps';

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      const { anonymousId } = useAnonymousClientStorage();
      const { getAnonymousData, createAnonymousId } = useClientAuthStore();
      const { isClientAuthenticated } = useAuthClientStorage();
      const { getClientInfo } = useClientInfo();
      const { anonymousData } = storeToRefs(useClientAuthStore());
      const { clientProfile, status } = storeToRefs(useClientInfo());
      const { currentRegisterStep } = useRegisterSteps();
      if (anonymousId.value || isClientAuthenticated.value) {
         if (isClientAuthenticated.value && !clientProfile.value) {
            await getClientInfo();
         } else if (!isClientAuthenticated.value && !anonymousData.value) {
            await getAnonymousData();
         }
         const isInsurance =
            clientProfile.value?.sponsor == SponsorType.Insurance ||
            anonymousData.value?.sponsor == SponsorType.Insurance;
         if (
            isInsurance &&
            status.value?.statusCode == 0 &&
            status.value?.route?.to?.name != to.name
         ) {
            const currentStepIndex = currentRegisterStep?.value?.findIndex(
               (step: RegisterStep) => step.to.name === to.name
            );
            const targetStepIndex = currentRegisterStep?.value?.findIndex(
               (step: RegisterStep) =>
                  step.to.name === status.value?.route?.to?.name
            );

            if (currentStepIndex < targetStepIndex) {
               return;
            } else {
               if (
                  status.value?.route?.to?.name ==
                  'auth-client-register-confirmedInsuranceBenefitCode'
               ) {
                  return;
               }
               return navigateTo(status.value?.route?.to);
            }
         }
      } else {
         if (to.name == 'auth-client-register-coverage') {
            return await createAnonymousId();
         } else {
            return navigateTo({ name: 'auth-client-login' });
         }
      }
      const sponsorType =
         clientProfile.value?.sponsor || anonymousData.value?.sponsor;
      const { verifyCompanyBenefitCode, getCoverageDetail } =
         useClientAuthStore();
      const { getTherapyTypes } = useSharedContentStore();
      const { therapyTypes } = storeToRefs(useSharedContentStore());
      const { updateAnonymousData, getNextStep } = useClientAuthStore();
      const currentStepIndex = currentRegisterStep?.value?.findIndex(
         (step: RegisterStep) => step.to.name === from.name
      );
      const targetStepIndex = currentRegisterStep?.value?.findIndex(
         (step: RegisterStep) => step.to.name === to.name
      );
      if (
         to.name == 'auth-client-register-therapy-type' &&
         sponsorType == 'company'
      ) {
         if (sponsorType == SponsorType.Company) {
            await verifyCompanyBenefitCode({
               benefit_code: anonymousData.value?.benefit_code as string,
               email: anonymousData.value?.email || '',
            });
         } else {
            await Promise.all([getTherapyTypes(), getCoverageDetail()]);
         }
         if (therapyTypes.value?.length == 1) {
            await updateAnonymousData({
               therapy_type_id: therapyTypes.value[0]?.id,
            });
         }
         if (currentStepIndex < targetStepIndex && currentStepIndex != -1) {
            const toRoute = getNextStep(true)?.to;
            if (toRoute) return navigateTo(toRoute);
         }
      }
      return;
   }
);
